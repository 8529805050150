const menuArray = [
  'tra-menu-home',
  'tra-menu-about',
  'tra-menu-skills',
  'tra-menu-work',
  'tra-menu-bio',
  'tra-menu-contact',
];

export default menuArray;
