import profilePic from '../assets/profile_pic.png';
import profilePicWebp from '../assets/profile_pic.webp';
import snecekLogo from '../assets/snecek_logo.jpg';
import traces from '../assets/traces_background.png';

const exports = {
  snecekLogo,
  profilePic,
  profilePicWebp,
  traces,
};

export default exports;
